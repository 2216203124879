export default class License {
    constructor(
        idLicense,
        days,
        id_user
    ) {
        this.idLicense = idLicense  ? idLicense : '',
        this.days = days ? days : 0,
        this.id_user = id_user ? id_user : ''
    }
}