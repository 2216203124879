export default class User {
    constructor(
        name, 
        last_name, 
        second_lastname, 
        birthdate, 
        id_cat_sex, 
        phone, 
        id_adm_organization, 
        email,
        password,
        id_role 
    ) {
        this.name = name ? name : '', 
        this.last_name = last_name ? last_name : '', 
        this.second_lastname = second_lastname ? second_lastname : '', 
        this.birthdate = birthdate ? birthdate : null,  
        this.id_cat_sex = id_cat_sex ? id_cat_sex : '', 
        this.phone = phone ? phone : '', 
        this.id_adm_organization = id_adm_organization ? id_adm_organization : '', 
        this.email = email ? email : '',
        this.password = password ? password : '',
        this.id_role = id_role ? id_role : ''
} 

}